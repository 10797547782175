import React from 'react'
import { Link } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'
import ReactGA from 'react-ga'

ReactGA.initialize('UA-50678207-7')

class SubscribePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        emailValue: '',
        fNameValue: '',
        lNameValue: '',
    };
  }

  handleFormSubmit(e) {
    const form = e.target;

    e.preventDefault();
    ReactGA.ga('send', 'event', {
      eventCategory: 'Email Submit Button',
      eventAction: 'Click',
      eventLabel: 'Email Submitted',
      hitCallback: () => {
        form.submit();
      }
    });
  }

    render() {
        return (
          <Layout>
            <div className="page-title">
              <div className="container">
                <h1>Join the Supergiant Games Mailing List</h1>
              </div>
            </div>
            <div className="container">
              <div style={{ textAlign: 'center' }}>
                <h1>Sign up now and never miss a beat from us here at Supergiant!</h1>
              </div>
              <div className="join-form">
                <img src="/kid_and_red.png" />
                <ul className="wy-plain-list-disc">
                  <li>- Be the first to hear about any new games we might be working on!</li>
                  <li>- Get deals and special offers on our full line of <a href="https://supergiant.games/merch">merchandise</a>!</li>
                  <li>- Stay on top of all the latest developments from our team!</li>
                </ul>
                <div style={{ background: '#404040', clear: 'left' }}>
                  <form action="https://supergiantgames.us13.list-manage.com/subscribe/post" method="POST" noValidate onSubmit={this.handleFormSubmit}>
                    <input type="hidden" name="u" value="f519b2fbd456ae854439ed997"/>
                    <input type="hidden" name="id" value="1eddce8289"/>
                    <fieldset>
                      <div className="wy-control-group">
                        <label htmlFor='MERGE0'>
                            Email
                        </label>
                        <div className="wy-control">
                          <input
                              type="email"
                              name="EMAIL"
                              id="MERGE0"
                              value={this.state.emailValue}
                              onChange={ (e)=>{this.setState({emailValue: e.target.value});} }
                              autoCapitalize="off"
                              autoCorrect="off"
                              style={{width: '75%'}}
                            />
                        </div>
                      </div>
                      <div className="wy-control-group">
                        <label htmlFor='MERGE1'>
                            First name
                        </label>
                        <div className="wy-control">
                            <input
                                type="text"
                                name="FNAME"
                                id="MERGE1"
                                value={this.state.fNameValue}
                                onChange={(e)=>{this.setState({fNameValue: e.target.value});}}
                                style={{width: '75%'}}
                            />
                        </div>
                      </div>

                      <div className="wy-control-group">
                        <label htmlFor='MERGE2'>
                            Last name
                        </label>
                        <div className="wy-control">
                            <input
                                type="text"
                                name="LNAME"
                                id="MERGE2"
                                value={this.state.lNameValue}
                                onChange={(e)=>{this.setState({lNameValue: e.target.value});}}
                                style={{width: '75%'}}
                            />
                        </div>
                      </div>
                      <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="btn btn-danger" />
                    </fieldset>
                    <span style={{ color: '#999', marginTop: '3px', fontSize: '77%' }}>
                      Note: Your sign-up information will only be used for the purpose of sending you news and offers from us at Supergiant. We will never share your information with any third party.
                    </span>
                    <div style={{position: 'absolute', left: '-5000px'}} aria-hidden='true' aria-label="Please leave the following three fields empty">
                        <label htmlFor="b_name">Name: </label>
                        <input type="text" name="b_name" tabIndex="-1" value="" placeholder="Freddie" id="b_name" readOnly/>

                        <label htmlFor="b_email">Email: </label>
                        <input type="email" name="b_email" tabIndex="-1" value="" placeholder="youremail@gmail.com" id="b_email" readOnly/>

                        <label htmlFor="b_comment">Comment: </label>
                        <textarea name="b_comment" tabIndex="-1" placeholder="Please comment" id="b_comment"></textarea>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Layout>
        )
    }
}

export default SubscribePage
